import * as React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"

const Footer = ({}) => (
    <footer
        style={{
            marginTop: `2rem`,
            }}
    >
            <div className="container">
                <div className="row">
                <div className="col-sm-12">
            <p>
                &copy;{new Date().getFullYear()} TaxRateInfo.com,&nbsp;
                <a href="https://www.boonmedia.com">Boon Media Inc.</a>&nbsp; &nbsp;
                <a href="/privacy">Privacy</a>&nbsp; &nbsp;
                <a href="/terms">Terms and Conditions</a>
                </p>
                <hr />
                This site is a free public service not affiliated with any governmental organization or the Internal Revenue Service.
                All precautions have been taken to ensure the information is correct and up-to-date; however, we are not liable for accuracy of this information.
            </div>
            </div>
            </div>
    </footer>
);

export default Footer
