import * as React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"
import Logo from "images/taxrateinfo_logo.svg";

const Header = ({siteTitle}) => (
  <nav className="navbar navbar-expand-lg navbar-light">
    <Link
      className="navbar-brand"
      to="/"><img src={Logo} alt={siteTitle}/></Link>

    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>

    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav mr-auto">
        <li className="nav-item">
          <Link className="nav-link" to="/">Home</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/federal-tax-rates-and-tax-brackets/2021">Federal Taxes</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/federal-tax-forms">Federal Tax Forms</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/tax-forms">State Tax Forms</Link>
        </li>
      </ul>
    </div>
  </nav>
);


Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header
