/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

 import * as React from "react"
 import PropTypes from "prop-types"
 import { useStaticQuery, graphql } from "gatsby"

 import Header from "./header"
 import Footer from "./footer"

 import "./layout.css"

 const Layout = ({ children }) => {
   const data = useStaticQuery(graphql`
     query SiteTitleQuery {
       site {
         siteMetadata {
           title
         }
       }
     }
   `)

   return (
     <>
       <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
       <div
         style={{
           margin: `0 auto`,
         }}
       >
         <main>{children}</main>

         <Footer />
       </div>
       <script type="text/javascript" src="https://platform-api.sharethis.com/js/sharethis.js#property=6081d0c1c61c85001820b9d5&product=inline-share-buttons" async="async"></script>
     </>
   )
 }

 Layout.propTypes = {
   children: PropTypes.node.isRequired,
 }

 export default Layout
